import React, { useContext, useEffect } from 'react';
import * as PropTypes from "prop-types";
import Head from 'next/head'
import Header from "../../layout-components/Header/Header";
import Footer from "../../layout-components/Footer/Footer";
import { useRouter } from "next/router";
import { hasClientToken, getJwtToken, removeJwtToken, setUITheme } from "../../helpers/utils";
import { isJwtExpired } from 'jwt-check-expiration';
import log from "../../lib/log";
import { APP } from "../../config/app_config";
import { useTheme } from "../../hooks/useTheme";

let classes = require("classnames");

const  AuthLayout = (props) => {
  let { header = true, headerMenu = false, footer = false, className = '', redirect=true } = props;

  let router = useRouter();
  const {theme, setTheme} = useTheme();


  useEffect(() => {
    try {
      const colorSchemeQueryList = window.matchMedia('(prefers-color-scheme: dark)');

      const setColorScheme = ({ matches, media }) => {
        if (matches) { // Dark
          setTheme('dark');
        } else { // Light
          setTheme('light');
        }
      }

      setColorScheme(colorSchemeQueryList);
      colorSchemeQueryList.addEventListener('change', setColorScheme);

    } catch (e) {
      log('SET_THEME_ERROR', "error", e,);
    }
  }, []);

  useEffect(() => {
    if (redirect) {
      router.prefetch('/home')
      router.prefetch('/login')

      try {
        if (hasClientToken()) {
          if (!(isJwtExpired(getJwtToken()))) {
            router.push('/home')
          }
        }
      } catch (e) {
        removeJwtToken();
        router.push('/login')
      }
    }
  }, []);

  return (
    <div className={classes(`AuthLayout ${className}`, {})}>
      {header && <Header brand={false} menu={headerMenu}/>}
      <main>
        {props.children}
      </main>
      {footer && <Footer fixedBottom={false}/>}
    </div>
  );
};

AuthLayout.propTypes = {
  headerMenu : PropTypes.bool,
  user : PropTypes.shape()
};

export default AuthLayout;
