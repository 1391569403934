import { Container, Row, Col } from "reactstrap";
import * as classes from "classnames";
import Link from "next/link";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";

export default function Custom404() {
  return (<AuthLayout headerMenu={false} footer={false}>
    <Container className=" NOT_FOUND_PAGE">
      <div className={classes("welcome text-center", {})}>
        <Row className="justify-content-center flex-column-reverse flex-md-row py-3 px-5">
          <Col sm={12} className={' bg-art'}>
            <img src="/img/backgrounds/Group%2021.svg" alt="Page not found artwork"/>
          </Col>
          <Col className={''}>
            <p className={'my-3 text-no'}>We can't seem to find the page you're looking for.</p>
            <p  className={'mb-1 mt-0'}>Here are some useful links instead</p>
            <p className={'text-small  line-height-1'}>
              <Link href={'/login'}><a className={'px-2'}>Home</a></Link>
            </p>
              <Link href={'/privacy-policy'}><a className={'px-2'}>Privacy policy</a></Link>
              <Link href={'/terms'}><a className={'px-2'}>Terms of Service</a></Link>
          </Col>
        </Row>
      </div>
    </Container>
  </AuthLayout>)
}
